<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <!-- main -->
    <b-row>
      <b-col lg="12">
        <b-card>
          <b-row class="vo-items-center">
            <b-col lg="4">
              <div class="vo-row vo-items-center">
                <ReferenceBy label="REFINED BY" />
                <b-input
                  style="flex: 1; margin-left: 10px"
                  v-model="search_condition.brandName"
                  placeholder="Search Brand"
                />
              </div>
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.businessType"
                :options="businessTypeOptions"
              />
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.status"
                :options="reviewOptions"
              />
            </b-col>
            <b-col>
              <b-select
                v-model="search_condition.enableStatus"
                :options="statusOptions"
              />
            </b-col>
            <b-col>
              <div class="vo-row vo-items-center vo-justify-between">
                <div class="custom-color cursor" @click="clearAll">
                  Clear All
                </div>
                <b-button variant="primary" @click="search">Search</b-button>
              </div>
            </b-col>
          </b-row>
          <!-- table -->
          <div class="table-responsive mb-0">
            <div v-if="isBusy">
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </div>
            <div v-else>
              <b-row
                cols-lg="4"
                cols-sm="2"
                cols-xl="6"
                cols-md="3"
                style="margin: 0"
              >
                <b-col
                  sm="6"
                  v-for="(item, index) in brandList"
                  :key="index"
                  class="mt-4 cursor"
                >
                  <template v-if="item.viewType === 'add'">
                    <CreateProduct @click="gotoAdd" name="Create Brand" />
                  </template>
                  <BrandItem
                    v-else
                    :item="item"
                    @click="onClickDetails(item, index)"
                    @onEditClick="edit(item, index)"
                    @onEnableOrDisableClick="
                      onEnableOrDisableClick(item, index)
                    "
                    @onClickReview="onClickReview(item, index)"
                    @onUserClick="onUserClick(item, index)"
                    @onSkuClick="onSkuClick(item, index)"
                  />
                </b-col>
              </b-row>
            </div>

            <!--
                        <b-table
                            show-empty
                            empty-text="No records"
                            id="device-table"
                            head-variant="light"
                            ref="table"
                            :busy="isBusy"
                            :striped="true"
                            :items="provider"
                            :fields="fields"
                            :per-page="search_condition.pageSize"
                            :current-page="search_condition.pageNum"
                            :sort-by.sync="sortBy"
                            :sort-desc.sync="sortDesc"
                            :filter="filter"
                            :filter-includedfields="filterOn"
                            @filtered="onFiltered"
                        >
                          <template v-slot:table-busy>
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"></b-spinner>
                              <strong>Loading...</strong>
                            </div>
                          </template>

                          <template v-slot:cell(index)="data">{{
                data.index +
                (search_condition.pageNum - 1) * search_condition.pageSize +
                1
            }}
          </template>

          <template v-slot:cell(brandPicture)="data">
            <img
                v-if="data.item.brandPicture"
                :src="`${data.item.brandPicture}`"
                alt
                class="avatar-xs rounded-circle mr-2"
            />
          </template>

          <template v-slot:cell(actions)="row">
            <b-button
                v-has="'brandManage::Modify'"
                v-if="row.item.supplierId > 0"
                size="sm"
                variant="primary"
                @click="edit(row.item, row.index, $event.target)"
                class="mr-1"
            >Modify
            </b-button>

            <b-button
                v-has="'brandManage::Details'"
                v-if="row.item.supplierId > 0"
                size="sm"
                variant="info"
                @click="onClickDetails(row.item, row.index, $event.target)"
                class="mr-1"
            >Details
            </b-button>

            <b-button
                v-if="row.item.supplierId > 0 && row.item.status == 0"
                v-has="'brandManage::Review'"
                size="sm"
                variant="warning"
                @click="onClickReview(row.item, row.index, $event.target)"
                class="mr-1"
            >
              Review
            </b-button>

            <b-button
                v-has="'brandManage::Delete'"
                v-if="row.item.supplierId > 0"
                size="sm"
                variant="danger"
                @click="onClickDelete(row.item, row.index, $event.target)"
                class="mr-1"
            >
              Delete
            </b-button>

            <b-button
                v-has="'brandManage::Down'"
                v-if="row.item.supplierId != 0"
                size="sm"
                variant="success"
                :disabled="dowmDisable"
                @click="down(row.item, row.index, $event.target)"
                class="mr-1"
            >Down link
            </b-button
            >
          </template>
        </b-table>

         -->
          </div>
          <!-- end table -->
          <!-- pagination -->
          <b-row v-if="has_data" class="mt-4">
            <b-col>
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination mb-0" v-if="rows != 0">
                  <li>
                    <p
                      class="pagination mb-0"
                      style="margin-top: 7px; margin-right: 20px"
                    >
                      {{ rows }} results
                    </p>
                  </li>
                  <b-pagination
                    ref="page"
                    v-model="search_condition.pageNum"
                    :per-page="btnMenu('brandMgmt::Create sdsdsdsds') ? 11 : 12"
                    :total-rows="rows"
                    @change="onPageChange"
                  ></b-pagination>
                </ul>
              </div>
            </b-col>
          </b-row>
          <!-- end pagination -->
        </b-card>
      </b-col>
    </b-row>
    <!-- end main -->

    <Dialog
      v-model="showAlert"
      :imageSrc="require('@/assets/confirm.svg')"
      content="showContent"
      okBtn="Yes"
      noBtn="No"
      @ok="enableOrDisableBrand"
      @cancel="showAlert = false"
    >
      <p>{{ showContent }}</p>
    </Dialog>

    <Dialog
      v-model="successAlear"
      :imageSrc="require('@/assets/success.png')"
      content="Saved successfully"
      noBtn="OK"
      @cancel="successAlear = false"
    >
      <p>Saved successfully</p>
    </Dialog>
  </Layout>
</template>
<script>
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import timeZoneConversion from "@/components/widgets/timeZoneConversion";
import ReferenceBy from "@/components/FindByLabel.vue";
import BrandItem from "@/components/brand/BrandItem.vue";
import FlavorItem from "@/components/flavor/FlavorItem.vue";
import Dialog from "@/components/Dialog.vue";
import CreateProduct from "@/components/product/CreateProduct.vue";

/**
 * Flavor List component
 */
export default {
  components: {
    Dialog,
    FlavorItem,
    BrandItem,
    ReferenceBy,
    DatePicker,
    Layout,
    PageHeader,
    timeZoneConversion,
    CreateProduct,
  },
  data() {
    return {
      title: "Brand Manage",
      items: [
        {
          text: this.$store.getters.getTitle,
          // href: "/"
        },
        {
          text: "Client&Brand",
          // href: "/"
        },
        {
          text: "Brand Manage",
          active: true,
        },
      ],
      tableData: [],
      totalRows: 0,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "index", label: "#", class: "text-center" },
        { key: "brandName", label: "Brand Name", class: "text-center" },
        // { key: "brandEncoding", label: "Brand No.", class: "text-center" },
        { key: "brandPicture", label: "Brand Logo", class: "text-center" },
        { key: "remarks", label: "Notes", class: "text-center" },
        {
          key: "status",
          label: "Review Status",
          formatter: (value) => {
            return value == 1
              ? "Passed"
              : value == 2
              ? "Not pass"
              : "Pending review";
          },
        },
        {
          key: "createTime",
          label: "Create Time",
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return timeZoneConversion.getLocalTime(value);
            }
          },
        },
        { key: "actions", label: "Opreration", class: "text-center" },
      ],
      reviewOptions: [
        { text: "--Review Status--", value: "", disabled: false },
        { text: "Pending Review", value: 0, disabled: false },
        { text: "Passed", value: 1, disabled: false },
        { text: "No passed", value: 2, disabled: false },
      ],
      statusOptions: [
        { text: "--All Status--", value: "", disabled: false },
        { text: "Enabled", value: 0, disabled: false },
        { text: "Disabled", value: 1, disabled: false },
      ],
      businessTypeOptions: [
        { text: "--All Business Type--", value: "" },
        { text: "CBD/THC Concentrates", value: 2 },
        { text: "Nicotine", value: 1 },
      ],

      search_condition: {
        pageNum: 1,
        pageSize: this.btnMenu("brandMgmt::Create 肯德基") ? 11 : 12,
        status: "",
        businessType: "",
        enableStatus: "",
        brandName: "",
        supplierId: localStorage.supplierId,
      },
      isBusy: false,
      has_data: false,
      dowmDisable: false,
      brandList: [],
      currentClickBrandItem: null,
      showAlert: false,
      showContent: "",
      successAlear: false,
    };
  },
  computed: {
    /**
     * 计算rows
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {},
  mounted() {
    this.loadData();
  },
  methods: {
    onUserClick(item, index) {},
    onSkuClick(item, index) {
      this.$router.push("brandProductMgmt?brandId=" + item.id);
    },
    onPageChange(page) {
      this.search_condition.pageNum = page;
      this.loadData();
    },
    //按钮权限判断
    btnMenu(value) {
      return this.$_has(value);
    },
    loadData() {
      //let add = this.$_has("brandMgmt::Create sdsdsdsds");
      this.isBusy = true;
      this.$api.Flavor.brandList(this.search_condition)
        .then((x) => {
          this.isBusy = false;
          if (x.total) {
            this.has_data = true;
            this.totalRows = x.total;
            this.brandList = x.data;
            if (this.btnMenu("brandMgmt::Create 肯德基")) {
              this.brandList.unshift({
                deviceImage: require("@/assets/images/common/ic_add.svg"),
                viewType: "add",
              });
            }
          } else {
            this.has_data = false;
            this.brandList = [];
          }
        })
        .catch((e) => {
          this.isBusy = false;
          this.has_data = false;
          this.brandList = [];
        });
    },
    search() {
      this.search_condition.pageNum = 1;
      this.loadData();
    },
    clearAll() {
      this.search_condition = {
        pageNum: 1,
        pageSize: this.btnMenu("brandMgmt::Create 肯德基") ? 11 : 12,
        status: "",
        businessType: "",
        enableStatus: "",
        brandName: "",
        supplierId: localStorage.supplierId,
      };
      this.loadData();
    },
    onClickDelete(item, index, button) {
      this.$bvModal
        .msgBoxConfirm("Are you sure to delete the brand?", {
          title: "Operation Brand",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.$api.Flavor.brandDelete({ id: item.id })
              .then((res) => {
                if (res.success) {
                  this.$refs.table.refresh();
                }
                if (res.message) {
                  this.$toast(res);
                }
              })
              .catch((res) => {
                this.$bvToast.toast(res.message);
              });
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },

    onClickDetails(item, index) {
      this.$router.push("brandMgmtDetails?id=" + item.id + "&opertionType=1");
    },
    //启用或者禁用弹框
    onEnableOrDisableClick(item, index) {
      //禁用或者启用
      let status = 0;
      if (item.enableStatus == 0) {
        status = 1;
        this.showContent = "Are sure to disable this brand?";
      } else {
        this.showContent = "Are sure to enble this brand?";
      }
      this.showAlert = true;
      this.postData = {
        id: item.id,
        status: status,
      };
    },
    onClickReview(item, index) {
      this.$router.push("brandMgmtDetails?id=" + item.id + "&opertionType=2");
    },
    //启用或者禁用品牌
    enableOrDisableBrand() {
      this.$api.Flavor.enableOrDisableBrand(this.postData)
        .then((res) => {
          if (res.success) {
            this.showAlert = false;
            this.successAlear = true;
            this.loadData();
          } else {
            if (res.message) {
              this.$toast(res);
            }
          }
        })
        .catch((res) => {
          this.$bvToast.toast(res.message);
        });
    },
    reviewBrand(status) {
      this.showAlert = false;
      this.$api.Flavor.reviewBrandById({
        id: this.currentClickBrandItem.id,
        status,
      }).then((res) => {
        if (res.success) {
          this.successAlert = true;
          this.loadData();
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.search_condition.pageNum = 1;
    },
    gotoAdd() {
      this.$router.push("brandMgmtAdd");
    },
    edit(item, index, button) {
      this.$router.push("brandMgmtEdit?id=" + item.id);
    },
    down(item, index, event) {
      this.dowmDisable = true;
      let data = {
        id: item.id,
      };
      this.$bvToast.toast("Creating...");
      try {
        this.$api.Flavor.downBrandLink(data);
        setTimeout(() => {
          this.dowmDisable = false;
        }, 6000);
      } catch (error) {
        alert(error);
      }
    },
  },
};
</script>
