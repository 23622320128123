<template>
  <MaskView
    @click="$emit('click')"
    borderStyle="border: 1px solid #d7d7d7"
    outerBorderStyle="1px dashed #02a7f0"
    :background-color="item.status !== 1 || item.enableStatus == 1 ? '#EEE' : ''"
  >
    <div class="vo-column align-items-center position-relative w-100 h-100">
      <div class="position-absolute img-wrap">
        <img
          style="width: 90%; height: 90%; object-fit: contain"
          :src="item.brandPicture"
        />
      </div>
      <div class="status position-absolute">
        <b-badge
          class="custom-badge"
          v-if="item.status !== 1 || item.enableStatus == 1"
          href="#"
          :variant="statusVariant"
          >{{ statusText }}
        </b-badge>
      </div>
    </div>
    <template #top>
      <div class="vo-row align-items-center vo-gap-4 justify-content-end p-2">
        <el-image
          v-if="item.enableStatus === 0 && item.status == 1"
          :src="require('@/assets/enable.svg')"
          class="cursor"
          @click.stop="$emit('onEnableOrDisableClick')"
        />
        <el-image
          v-if="item.enableStatus === 1 && item.status == 1"
          :src="require('@/assets/disable.svg')"
          class="cursor"
          @click.stop="$emit('onEnableOrDisableClick')"
        />
        <el-image
          v-if="item.status === 0"
          :src="require('@/assets/review.svg')"
          class="cursor"
          @click.stop="$emit('onClickReview')"
        />
        <el-image
          :src="require('@/assets/edit.svg')"
          class="cursor"
          @click.stop="$emit('onEditClick')"
        />
      </div>
    </template>
    <template #bottom>
      <div class="vo-row align-items-center justify-content-around flex-wrap mb-1 ml-1 mr-1"  style="gap: 10px">
        <b-button
        style="flex: 1"
          class="operate-btn"
          @click.stop="$emit('onSkuClick')"
          variant="primary"
          >sku
        </b-button>
        <b-button
        style="flex: 1"
          class="operate-btn"
          @click.stop="$emit('onUserClick')"
          variant="primary"
          >User
        </b-button>
      </div>
    </template>
  </MaskView>
</template>

<script>
import MaskView from "@/components/MaskView.vue";

export default {
  name: "BrandItem",
  components: { MaskView },
  props: {
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    statusVariant() {
      const status = this.item.status;
      const enableStatus = this.item.enableStatus;
      if (status == 0) {
        return "warning";
      } else if (status == 2) {
        return "danger";
      } else if (enableStatus == 1) {
        return "danger";
      }
    },
    statusText() {
      const status = this.item.status;
      const enableStatus = this.item.enableStatus;
      if (status == 0) {
        return "Pending Review";
      } else if (status == 2) {
        return "No passed";
      } else if (enableStatus == 1) {
        return "Disable";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.status {
  top: 0px;
  left: 0;
  font-size: 20px;
}

::v-deep .badge {
  border-radius: 0;
}

.operate-btn {
  width: 80px;
}

.img-wrap {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}

.custom-badge{
  height: 30px; /* 设置高度 */
  line-height: 30px; /* 行高与高度相同，使文本垂直居中 */
  display: flex;
  align-items: center; /* 垂直居中 */
  justify-content: center; /* 水平居中 */
  text-align: center; /* 确保文本居中对齐 */
  margin-top: 12px;
}
</style>